import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-tabla',
  templateUrl: './dashboard-tabla.component.html',
  styleUrls: ['./dashboard-tabla.component.css']
})
export class DashboardTablaComponent implements OnInit {
    @Input() titulos:Array<any>;
    @Input() valores:Array<any>;
    @Input() formatos:Array<any>;
    private totales:Array<number>=[];
    private tipos:Array<any>=[];
    constructor() { }

    ngOnInit() {
        this.llenarArrayTotales();
    }

    llenarArrayTotales(){
        let valor:any;
        for(let i = 0; i<this.valores.length; i++){
            for(let j = 1; j<this.valores[i].length; j++){//Empieza en 1 por que en la inicial va el título de Totales
                valor = ""+this.valores[i][j];                
                if(valor.indexOf("$")!=-1){
                    valor = valor.replace("$","");
                    valor = valor.replace(/,/g,"");
                    this.totales[j-1]=this.totales[j-1]+Number(valor) || Number(valor);
                    this.tipos[j-1]  ='M';
                }else{
                    valor = valor.replace(/,/g,"");
                    if(!isNaN(valor)){//isNan devuelve false cuando si se puede pasar a numerico
                        valor = Number(valor);
                        this.tipos[j-1]='N';
                    }else{
                        this.tipos[j-1]='T';
                    }
                    this.totales[j-1]=this.totales[j-1]+Number(valor) || Number(valor) || null;                    
                }          
            }
        }
    }

}
