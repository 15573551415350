import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-campos-multi-registro',
  templateUrl: './campos-multi-registro.component.html',
  styleUrls: ['./campos-multi-registro.component.css']
})
export class CamposMultiRegistroComponent implements OnInit {
    @Input() valores:any;//Toca traerlos todos por problemas de interpolación
    @Input() pos:any; 
    @Input() tipo:string;
    @Input() tipoda:string;
    @Input() opcionesCampos:any;
    @Input() deshabilitado:boolean;
    @Input() idReg:string;
    @Input() idMenu:string;
    @Input() superior:string;
    @Output() enviarPeticionRecarga = new EventEmitter();
    public contenido:any='';
    public tituloCheck:string;
    public opciones:any;
    constructor() { }

    ngOnInit() {
        this.contenido ='';
        this.ponerOpcionesCampo();
        if(this.tipo == 'S' && this.valores[this.pos]!=''){
            this.ponerValorSelect();    
        }else if(this.tipo == 'C' && this.valores[this.pos]!=''){
            this.ponerValorCheck();
        }else{
            this.contenido = this.valores[this.pos];
        }    
    }

    ponerOpcionesCampo(){
        for(let i = 0; i<this.opcionesCampos.length;i++){
            if(this.opcionesCampos[i].columna == this.pos){
                if(this.opcionesCampos[i].opcionesSelect!=null){
                    this.opciones = this.opcionesCampos[i].opcionesSelect; 
                }else if(this.opcionesCampos[i].opcionesCheck!=null){
                    this.opciones = this.opcionesCampos[i].opcionesCheck;
                }
            }
        }
    }

    ponerValorSelect(){
        if(this.opciones[0].id!='' && this.tipoda=='T'){
            this.opciones.splice(0,0,{'id':'','name':'Seleccione'});
        }else if(this.opciones[0].id!='0' && this.tipoda=='N'){
            this.opciones.splice(0,0,{'id':'0','name':'Seleccione'});
        }
        for(let i = 0; i<this.opciones.length; i++){
            if(this.opciones[i].id == this.valores[this.pos]){
                this.contenido = this.opciones[i];
            }
        }
    }

    ponerValorCheck(){
        if(this.valores[this.pos] == this.opciones.true.valor){
            this.contenido = true;
            this.tituloCheck = this.opciones.true.titulo;
        }else{
            this.contenido = false;
            this.tituloCheck = this.opciones.false.titulo;
        }
    }

    asignarValorCampo(tipo:string){
        if(tipo == 'S'){//Select
            this.valores[this.pos] = this.contenido.id;
        }else if(tipo == 'T'){//Texto
            this.valores[this.pos] = this.contenido;
        }else{//Check
            if(this.contenido){
                this.valores[this.pos] = this.opciones.true.valor;
                this.tituloCheck = this.opciones.true.titulo;
            }else{
                this.valores[this.pos] = this.opciones.false.valor;
                this.tituloCheck = this.opciones.false.titulo;
            }
        }
        this.enviarPeticionRecarga.emit({idmenu:this.idMenu, valor:this.contenido.id,posicion:this.pos, superior:this.superior});
    }
}