import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stringCommasToArray'
})

//Pipe para convertir un string que viene separado por comas a Array
export class StringCommasToArrayPipe implements PipeTransform {

	public transform(value: any): any {
		let array = [];
		if(value){
			if (value.indexOf(",") != -1) {
				array = value.split(",");
			} else {
				array.push(value);
			}
		}		
		return array;
	}
}
