export class Tab{
    public id:string;//Id del menu seleccionado
    public titulo:string;//Título del menú seleccionado
    public ruta:string;//Ruta del menu 
    
    constructor(datosMenu){
        this.id=datosMenu.id;
        this.titulo=datosMenu.titulo;
        this.ruta='';
    }
} 