import { campoFormulario } from './campo'

export class Parametro{
    public nombre:string;
    public tipoObjeto:string;
    public tipoDato:string;
    public longitud:string;
    public ayuda:string;
    public funcion:string;
    public evento:any;
    public etiqueta:string;
    public requerido:string;
    public estilos_conf:string;
    public estilos_pres:string;
    public nombre_boton:string;
    public bloquea_campos:string;
    public busqueda:string;
    public opciones:any;
    public opcionesCheck:any;
    public opcionesAgregacion:any;
    public opcionesAutocompletar:any;
    public opcionesListaAvanzada:any;
    public estructuraFormulario:any;
    public valoresEstructura:any;
    public recargaObjeto:string;
    public valor:any;
    public contenido:any;//Contenido que se utilizará en caso de ser select, autocompletar, lista de seleccion avanzada
    public mensaje:string;
    public referidos:Array<campoFormulario>;
    public referidosBloqueo:Array<campoFormulario>
    public referidosReporte:Array<campoFormulario>
    public identificadorPadre:string;//Id del registro padre en el caso que sea un tipo J
    public inmodificable:string;
    public adicionar:string;
    public defecto:string;
    public bloqueado:boolean;
    public aceptaMinusculas:string;
    public aceptaEspeciales:string;
    public manejaFase:boolean;
    public campoReferencia:string;
    public mensajeValidacion:string;
    public recargaPadre:boolean;
    public primario:string;

    constructor(parametro:any){
        this.nombre=parametro.nombre;
        this.tipoObjeto=parametro.tipoObjeto;
        this.tipoDato=parametro.tipoDato;
        this.longitud=parametro.longitud;
        this.ayuda=parametro.ayuda;
        this.funcion=parametro.funcion;
        this.evento=parametro.evento;
        this.etiqueta=parametro.etiqueta;
        this.requerido=parametro.requerido;
        this.estilos_conf=parametro.estilos_conf;
        this.estilos_pres=parametro.estilos_pres ? parametro.estilos_pres.toLowerCase():null;
        this.nombre_boton=parametro.nombre_boton;
        this.bloquea_campos=parametro.bloquea_campos;
        this.opciones=parametro.opciones;
        this.opcionesCheck=parametro.opcionesCheck;
        this.opcionesAgregacion=parametro.opcionesAgregacion;
        this.opcionesAutocompletar=parametro.opcionesAutocompletar;
        this.opcionesListaAvanzada=parametro.opcionesListaAvanzada;
        this.estructuraFormulario = parametro.estructuraFormulario;
        this.valoresEstructura = null;
        this.recargaObjeto=parametro.recargaObjeto;
        if(this.tipoObjeto=='U' || this.tipoObjeto=='K'){//Si es carga de archivos pone un valor por defecto
            this.valor = 'Pendiente';
        }else{
            this.valor='';
        }
        this.contenido = null;
        this.mensaje = '';
        this.identificadorPadre = '';
        this.busqueda = parametro.busqueda;
        if(this.tipoObjeto=='1'){//La lista de seleccion avanzada contiene los referidos dentro de su estructura
            this.llenarReferidos(parametro.opcionesListaAvanzada.referidos);
        }else{
            this.llenarReferidos(parametro.referidosRecarga);
        }        
        this.llenarReferidosBloqueo(parametro.referidosBloqueo);
        this.llenarReferidosReporte(parametro.referidosReporte);
        this.inmodificable = parametro.inmodificable;
        this.adicionar = parametro.adicionar;
        this.defecto = parametro.defecto;
        this.bloqueado = false;
        this.aceptaMinusculas = parametro.minusculas;
        this.aceptaEspeciales = parametro.especiales;
        this.manejaFase = parametro.manejaFase;
        this.campoReferencia = parametro.campoReferencia;
        this.mensajeValidacion = parametro.mensajeValidacion;
        this.recargaPadre = parametro.recargaPadre;
        this.primario = parametro.primario;
        if(this.tipoObjeto == 'S' || this.tipoObjeto == 'N'){
            this.ponerVacioSelect();
        }
    }

    llenarReferidos(campos:Array<string>){   
        if(campos!=undefined && campos!=null && campos.length>=1){
            this.referidos = [];
            for(let i=0; i<campos.length;i++){
                this.referidos.push(new campoFormulario(campos[i],'',''));
            }
        }else{
            this.referidos = null;
        }
    }
    llenarReferidosBloqueo(campos:Array<string>){
        if(campos!=null && campos.length>=1){
            this.referidosBloqueo = [];
            for(let i=0; i<campos.length; i++){
                this.referidosBloqueo.push(new campoFormulario(campos[i],'',''));
            }
        }else{
            this.referidosBloqueo = null;
        }
    }

    llenarReferidosReporte(campos:Array<string>){
        if(campos!=null && campos.length>=1){
            this.referidosReporte = [];
            for(let i=0; i<campos.length; i++){
                this.referidosReporte.push(new campoFormulario(campos[i],'',''));
            }
        }else{
            this.referidosReporte = null;
        }
    }

    ponerVacioSelect(){
        if(this.opciones){//  && this.opciones.length>=1
            if(this.tipoObjeto=='S' && (!this.opciones[0] || this.opciones[0].id!='')){//Si no tiene un valor nulo se lo adiciono
                this.opciones.unshift({'id':'','name':'Seleccione'});                
            }else if(this.tipoObjeto=='N' && (!this.opciones[0] || this.opciones[0].id!='0')){//Si no tiene un valor nulo se lo adiciono
                this.opciones.unshift({'id':'0','name':'Seleccione'});
            }
            this.contenido = this.opciones[0];
            this.valor = this.contenido.id;
        }      
    }
} 