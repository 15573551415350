import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OperacionesService {

    constructor() { }

    tomarValorIdLineal(campoLineal: string, camposPadre: any) {
        let valorCampoLineal: string = null;
        let i = 0;
        while (i < camposPadre.length) {
            if (camposPadre[i].nombre === campoLineal) {
                valorCampoLineal = camposPadre[i].valor;
                i = camposPadre.length;
            }
            i++
        }
        return valorCampoLineal;
    }
}
