import { Parametro } from './parametro';

export class Division {
  public id: string;
  public estilo_borde: string;
  public titulo: string;//
  public tam_columna: string;
  public ayuda: string;
  public parametros: Array<Parametro> = [];
  public oculto: boolean;
  public estilo: string;

  constructor(division) {
    this.id = division.id;
    this.estilo_borde = division.estilo_borde;
    this.titulo = division.titulo;
    this.tam_columna = division.tam_columna;
    this.ayuda = division.ayuda;
    this.estilo = division.estilo;

    if (this.estilo == 'oculto') {
      this.oculto = true;
    } else {
      this.oculto = false;
    }
    this.adicionarParametros(division.parametros);
  }

  adicionarParametros(parametros) {
    if (parametros != null) {
      for (let i = 0; i < parametros.length; i++) {
        this.parametros.push(new Parametro(parametros[i]));
      }
    } else {
      this.parametros = null;
    }
  }
}
