import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'; //viewChild es para saber si puedo ejecutar funciones del hijo
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { campoFormulario } from '@modelos/campo';

@Component({
  selector: 'app-contenedor-formulario',
  templateUrl: './contenedor-formulario.component.html',
  styleUrls: ['./contenedor-formulario.component.css']
})

export class ContenedorFormularioComponent implements OnInit {
  @Input() idmenu: String;//Id del menú para generar formulario
  @Input() origen: string; // Origen de contenedor, menu o chtm
  @Input() idchtm: number; // Id del chtm, normalmente solo se recibe cuando es un formulario que se necesita en un dialogo
  @Input() identificadorDialogo: string; // Identificador de registro, solo se recibe cuando se abre desde dialogo y es formulario de unico registro
  @Input() camposDialogo: campoFormulario[]; // Campos que vienen desde el padrastro, solo se recibe cuando se abre formulario en el dialogo
  @Input() camposPadre: any;
  @Output() enviarRutaFormulario = new EventEmitter();//Funcionalidad para enviar el título del formulario a los tabs abiertos
  @Output() enviarMensajeComponente = new EventEmitter();
  public loading: boolean = false;
  public mensaje: string;//Mensajes que saldran en el proceso y deberán ser mostrados
  public llamado: any;//Json con los llamados a enviar al api-rest
  public jsonFormulario: any = null;//Json que contendrá los datos del formulario a construir
  public tablaResultados: any = null;//Tabla con la estrucutra y datos a puntar en los formularios de texto
  public idFormulario: string;
  public identificador: string = null;// Identificador de registr cuando se realiza busqueda en formulario unico registro
  public abrirFormulario: boolean;
  public accion: string;//Accion de formulario, a adicionar, esto para saber que campos se deben bloquear
  public registroFormulario: any = null;
  public camposRecargar: any = null;
  public camposBloquearOcultar: any = null;
  constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService) { }

  ngOnInit() {
    this.tablaResultados = null;
    this.accion = 'A';
    this.obtenerDatosFormulario();
  }

  // función que consume al bus de servicio solicitando el tipo de formulario y su estructura
  obtenerDatosFormulario() {
    this.loading = true;
    this.abrirFormulario = true;
    let tipo: string;
    let codigoSesion = this._servicioDatosGenerales.getIdentity().codigoSesion;
    this.llamado = { "accion": "informacionInicialFormulario", "parametros": { "codigoSesion": codigoSesion, "id_menu": this.idmenu || "", "idFormulario": this.idchtm || "", "campos": (this.camposDialogo && this.camposDialogo.length) ? this.camposDialogo : [], "identificador": this.identificadorDialogo || "" } };
    this._servicioPeticiones.enviarObjetoBus(this.llamado)
      .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response;
          let codigoPaso: string;
          let resultado: any;
          let i = 0;
          while (i < respuesta.length) {
            resultado = respuesta[i].valores;
            if (resultado && resultado.resultado == 0) {
              codigoPaso = respuesta[i].codigPaso;
              if (codigoPaso == 'I') {//Inicialmente, carga el tipo de formulario
                this.idFormulario = resultado.formulario.id;
                tipo = resultado.formulario.tipo;
                if (resultado.formulario.rutaFormulario && resultado.formulario.rutaFormulario != '') {
                  this.enviarRutaFormulario.emit({ id: this.idmenu, ruta: resultado.formulario.rutaFormulario });
                }
              } else if (codigoPaso == '2') {//Realiza la busqueda en el caso que se necesite(Si formulario es dde texto)
                this.tablaResultados = resultado.tablaResultados;
                if (tipo == 'U') {
                  this.registroFormulario = resultado.registro;
                  this.identificador = this.registroFormulario ? this.registroFormulario.identificador : null; // Se envía el registro para que cargue bien a los hijos relacionados
                  this.accion = this.registroFormulario ? 'M' : 'A';
                  // this.abrirFormulario = true;
                  this.ejecutarRecargasBloqueosFormulario();
                } else {
                  this.abrirFormulario = false;
                }
              } else if (codigoPaso == '3') {//Construccion de formulario
                this.jsonFormulario = resultado.formulario;
              }
            } else {
              i = 3;
              this.enviarMensajeComponente.emit({ tipo: 'I', mensaje: "Formulario en construcción" });
              this.loading = false;
            }
            i++;
          }
          if (tipo != 'U') {
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.mensaje = 'Ocurrió un error inesperado al intentar cargar el formulario, por favor contáctese con el administrador del sistema';
          this.enviarMensajeComponente.emit({ tipo: 'E', mensaje: this.mensaje });
        }
      )
  }

  ejecutarRecargasBloqueosFormulario() {
    let codigoSesion = this._servicioDatosGenerales.getIdentity().codigoSesion;
    this.llamado = { "accion": "recargarBloquearFormulario", "parametros": { "codigoSesion": codigoSesion, "formulario": this.idFormulario, "campos": this.registroFormulario.campos } };
    this._servicioPeticiones.enviarObjetoBus(this.llamado)
      .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response;
          let codigoPaso: string;
          let resultado: any;
          for (let i = 0; i < respuesta.length; i++) {
            resultado = respuesta[i].valores;
            if (resultado && resultado.resultado == 0) {
              codigoPaso = respuesta[i].codigPaso;
              if (codigoPaso == 'I') {//Campos a recargar
                if (resultado.campos && resultado.campos.length > 0) {
                  this.camposRecargar = resultado.campos;
                }
              } else if (codigoPaso == '2') {//Bloquear ocultar
                if (resultado.campos && resultado.campos.length > 0) {
                  this.camposBloquearOcultar = resultado.campos;
                }
              }
            }
          }
          this.loading = false;
          this.abrirFormulario = true;
        },
        error => {
          this.loading = false;
          this.mensaje = 'Ocurrió un error inesperado al intentar cargar el formulario, por favor contáctese con el administrador del sistema';
          this.enviarMensajeComponente.emit({ tipo: 'E', mensaje: this.mensaje });
        }
      )
  }

  cerrarFormularioCampos() {
    this.abrirFormulario = false;
  }
}
