import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
    @Input() menu:any;
    @Input() busqueda:boolean = false;//Indica si es menu por búsqueda o no, ésto para saber como se debe emitir el id del menu cuando se de click en él
    @Output() enviarMenuId = new EventEmitter();
    @Output() enviarIdMenuGeneral = new EventEmitter();
    public activo:boolean = false;
    constructor() { }

    ngOnInit() {
    }

    //Función que envía el id del menu seleccionado al dar click en un menú
    generarFormulario(){
        if(this.busqueda ==false){//Si no es un menu con busqueda, se emite a si mismo devolviéndose rama a rama ya que el menú se construyo recursivo
            this.enviarMenuId.emit({id:this.menu.id, tipo:this.menu.tipo, titulo:this.menu.titulo});
        }
        else{//Cuando si es por busqueda, emite directamente al menu principal ya que el menú solo es de un nivel y no se tuvo que construir recursivamente
            this.enviarIdMenuGeneral.emit({id:this.menu.id, titulo:this.menu.titulo});
        }
    }

    //Función que recibe el id del menú hoja desde el cual se dio click y que dependiendo de los niveles se va devlviendo a su rama padre hasta llegar a título para poder enviar el valor de id seeccionado al componente menu
    tomarValorIdMenu(event){
        if(this.menu.tipo =='H' || this.menu.tipo =='R'){//Si es hoja o ramificación, se devuelve una rama mas arriba 
            this.enviarMenuId.emit({id:event.id,tipo:event.tipo, titulo:event.titulo});
        }else{//Cuando llego a su menu principal, envía el valor a el componente menu solo
            this.enviarIdMenuGeneral.emit({id:event.id, titulo:event.titulo});
        }
    }

    // Función que cambia la clase activa del menú para con esto desplegarlo
    cambiarClaseMenu(){
        if(this.menu.tipo =='R'){
            this.activo = !this.activo;
        }
    }
}