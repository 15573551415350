import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';//Esto sirve para que al actualizar el navegador no salga eror 404
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';// poner si falla ReactiveFormsModule

import { ToolbarModule } from 'primeng/toolbar';
import { servicioPeticiones } from './services/servicioPeticiones';
import { DatosGeneralesService } from './services/datosGenerales.service';
import { funcionesSesion } from './funcionesGenerales/funcionesSesion';
import { OperacionesService } from './services/operaciones.service'
import { ComunicacionService } from './services/comunicacion.service';

import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { DashboardComponent } from './component/componentes-formulario/dashboard/dashboard.component';
import { MenuComponent } from './component/menu/menu/menu.component';
import { MenuItemComponent } from './component/menu/menu-item/menu-item.component';

// Componentes de primeNG
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { KeyFilterModule } from 'primeng/keyfilter';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MenuModule } from 'primeng/menu';
import { GalleriaModule } from 'primeng/galleria';
import { StepsModule } from 'primeng/steps';


import { SafePipe } from './pipes/safe.pipe';
import { StringCommasToArrayPipe } from './pipes/string-commas-to-array.pipe';

import { CamposFormularioComponent } from './component/componentes-formulario/campos-formulario/campos-formulario.component';
import { DireccionEstandarComponent } from './component/componentes-formulario/direccion-estandar/direccion-estandar.component';
import { FormularioEstructuraComponent } from './component/componentes-formulario/formulario-estructura/formulario-estructura.component';
import { FormularioTablaComponent } from './component/componentes-formulario/formulario-tabla/formulario-tabla.component';
import { VistaPreviaComponent } from './component/componentes-formulario/vista-previa/vista-previa.component';
import { ContenedorFormularioComponent } from './component/componentes-formulario/contenedor-formulario/contenedor-formulario.component';

import { UserIdleModule } from 'angular-user-idle';// Para detectar el tiempo de inactividad
import { ClipboardModule } from 'ngx-clipboard'; //Función para copiar en portapapeles
import { FormularioMultiregistroComponent } from './component/componentes-formulario/formulario-multiregistro/formulario-multiregistro.component';
import { CamposMultiRegistroComponent } from './component/componentes-formulario/campos-multi-registro/campos-multi-registro.component';
import { ListaAgregacionJsonComponent } from './component/componentes-formulario/lista-agregacion-json/lista-agregacion-json.component';
import { CamposJsonComponent } from './component/componentes-formulario/campos-json/campos-json.component';
import { FormularioConfirmacionUsuarioComponent } from './component/componentes-formulario/formulario-confirmacion-usuario/formulario-confirmacion-usuario.component';
import { FormularioBloqueoComponent } from './component/formulario-bloqueo/formulario-bloqueo.component';
import { DashboardTablaComponent } from './component/componentes-formulario/dashboard/dashboard-tabla/dashboard-tabla.component';
import { FormularioDialogoTablaComponent } from './component/componentes-formulario/formulario-dialogo-tabla/formulario-dialogo-tabla.component';
import { VisorDocumentosComponent } from './component/componentes-formulario/visor-documentos/visor-documentos.component';
import { StringLengthPipe } from './pipes/string-length.pipe';
import { RegistarseContratistaComponent } from './component/componentes-formulario/registarse-contratista/registarse-contratista.component';

/*Configuración de las rutas */
const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:codigo', component: LoginComponent },
  { path: 'login/:codigo/:tipo', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/:estado', component: HomeComponent },
  { path: 'registrarse', component: RegistarseContratistaComponent },
  { path: '**', component: LoginComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    MenuComponent,
    MenuItemComponent,
    CamposFormularioComponent,
    DireccionEstandarComponent,
    FormularioEstructuraComponent,
    FormularioTablaComponent,
    VistaPreviaComponent,
    ContenedorFormularioComponent,
    FormularioMultiregistroComponent,
    CamposMultiRegistroComponent,
    ListaAgregacionJsonComponent,
    CamposJsonComponent,
    FormularioConfirmacionUsuarioComponent,
    FormularioBloqueoComponent,
    DashboardTablaComponent,
    SafePipe,
    StringCommasToArrayPipe,
    FormularioDialogoTablaComponent,
    VisorDocumentosComponent,
    StringLengthPipe,
    RegistarseContratistaComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,//Mirar si es necesario para los formularios
    HttpClientModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    TabViewModule,
    ChartModule,
    TableModule,
    TooltipModule,
    PaginatorModule,
    AutoCompleteModule,
    CalendarModule,
    EditorModule,
    SplitButtonModule,
    RadioButtonModule,
    InputTextareaModule,
    FileUploadModule,
    DialogModule,
    ClipboardModule,
    PasswordModule,
    KeyFilterModule,
    InputMaskModule,
    CurrencyMaskModule,
    ScrollPanelModule,
    TieredMenuModule,
    MenuModule,
    StepsModule,
    ToolbarModule,
    GalleriaModule,
    UserIdleModule.forRoot({})
  ],
  providers: [servicioPeticiones, DatosGeneralesService, funcionesSesion, OperacionesService, MessageService,
    ComunicacionService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
