import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-formulario-bloqueo',
  templateUrl: './formulario-bloqueo.component.html',
  styleUrls: ['./formulario-bloqueo.component.css']
})
export class FormularioBloqueoComponent implements OnInit {
  @Input() identity: any;
  @Input() mensaje: string;
  @Input() imagen: string;
  @Output() enviarPeticionDesbloqueo = new EventEmitter();
  @Output() enviarPeticionCerrarSesion = new EventEmitter();
  public contrasena: string;

  constructor() { }

  ngOnInit() {
    this.contrasena = '';
  }

  activarSesion() {
    if (this.contrasena != '') {
      this.enviarPeticionDesbloqueo.emit(this.contrasena);
    } else {
      this.mensaje = 'Debe ingresar una contraseña';
    }
    this.contrasena = '';
  }

  cerrarSesion() {
    this.enviarPeticionCerrarSesion.emit();
  }
}
