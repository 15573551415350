import { campoFormulario } from './campo';

export class RegistroTabla{
    public id:number;
    public idTabla:string;//Id que está almacenado en la tabla de la base de datos, ésto para manejo de formulario tipo R
    public campos:Array<campoFormulario>;
    public editable:string;
    public eliminable:string;

    constructor(id:number,campos:any, idEnTabla:string, editable:string, eliminable:string){
        this.id = id;
        this.campos = campos;
        this.idTabla = idEnTabla;
        this.editable = editable;
        this.eliminable = eliminable;
    }
}