import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { funcionesSesion } from '@funcionesGenerales/funcionesSesion';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-formulario-confirmacion-usuario',
  templateUrl: './formulario-confirmacion-usuario.component.html',
  styleUrls: ['./formulario-confirmacion-usuario.component.css']
})
export class FormularioConfirmacionUsuarioComponent implements OnInit {
    @Input() accion:string;
    @Input() permisoNuevo:string;
    @Input() permisoEditar:string;
    @Input() permisoEliminar:string;
    @Input() formulario:string;
    @Output() enviarSenalCerrar = new EventEmitter();
    @Output() confirmarAccionUsuario = new EventEmitter();
    public formValido:boolean = true;
    public loading:boolean=false;
    public titulo:string = '';
    private codigoAcceso:string;
    private idAcceso:string;
    public password:string='';
    public userform: FormGroup;

    constructor(private fb: FormBuilder, private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService, private _funcionesSesion: funcionesSesion) { }

    ngOnInit() {
        this.userform = this.fb.group({
            'username': new FormControl({value:'', disabled:(this.accion == 'A' && this.permisoNuevo == 'U') || (this.accion == 'M' && this.permisoEditar == 'U') || (this.accion == 'E' && this.permisoEliminar == 'U')}, Validators.compose([Validators.required, Validators.pattern('^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*')])),
            'password': new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]))
        });

        if((this.accion == 'A' && this.permisoNuevo == 'U') || (this.accion == 'M' && this.permisoEditar == 'U') || (this.accion == 'E' && this.permisoEliminar == 'U')){
            this.password = this._servicioDatosGenerales.getIdentity().usuario.codigo+'@'+this._servicioDatosGenerales.getIdentity().usuario.dominio;
            this.userform.controls['username'].setValue(this.password)
            this.titulo = 'Necesita confirmar los datos de usuario!';
        }else{
            this.titulo = 'Necesita permisos del administrador del sistema!';
        }
        this.solicitarLlaveLogin();
    }

    cerrarFormulario(){
        this.enviarSenalCerrar.emit();
    }

    //Método que se ejecuta al ingresar al sistema y realiza la petición al servicio de autenticación solicitando una llave inicial de acceso
    solicitarLlaveLogin() {
        let llamado = {"accion":"inicio"}
        this.loading = true;
        this._servicioPeticiones.enviarObjetoBus(llamado)
        .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
            response => {
                let respuesta = response[0];//Obtengo la respuesta del api rest
                if(respuesta.valores !=null){
                    if(respuesta.valores.resultado == 0){//Conexion sin inconvenientes
                        this.codigoAcceso = respuesta.valores.codigo_inicial_acceso;//Obtengo la llave privada
                        this.idAcceso = respuesta.valores.id;
                    }else{
                        this._servicioDatosGenerales.enviarMensaje('E',respuesta.valores.mensaje);
                    }  
                }else{
                    this._servicioDatosGenerales.enviarMensaje('E',this._servicioDatosGenerales.getMensajeError());
                }   
                this.loading = false;                                                     
            },
            error => {
                this.loading = false;
                this._servicioDatosGenerales.enviarMensaje('E',this._servicioDatosGenerales.getMensajeError());
            })
    }

    verificarDatosAdministrador(datosUsuario:any){
        this.formValido = this.userform.valid;
        if(this.formValido){
            this.loading = true;
            let llamado = {"accion":"permisoUsuario","parametros":{"formulario":this.formulario,"id":this.idAcceso,"operacion":this.accion,"codigoSesion":this._servicioDatosGenerales.getIdentity().codigoSesion,"usuario":this.password!=''?this.password:datosUsuario.username,"password":this._funcionesSesion.encriptarRsa(datosUsuario.password,this.codigoAcceso)}};
            this._servicioPeticiones.enviarObjetoBus(llamado)
                .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                    response => { //si la peticion fue exitosa ejecuta la funcion response
                        let respuesta = response[0].valores;
                        if(respuesta.resultado == 0){
                            this.confirmarAccionUsuario.emit(respuesta.codigoAutorizacion);
                        }else{
                            this.idAcceso = respuesta.id;
                            this.codigoAcceso = respuesta.codigo_inicial_acceso;
                            this._servicioDatosGenerales.enviarMensaje('E',respuesta.mensaje);
                        }
                        this.loading = false;
                    },
                    error => {
                        this._servicioDatosGenerales.enviarMensaje('E',this._servicioDatosGenerales.getMensajeError());
                    }
                )
        }
    }   
}