import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Direccion } from '@modelos/direccion' 

@Component({
  selector: 'app-direccion-estandar',
  templateUrl: './direccion-estandar.component.html',
  styleUrls: ['./direccion-estandar.component.css']
})
export class DireccionEstandarComponent implements OnInit {
    @Input() abrirDialogo:string;
    @Output() enviarSenalCerrar = new EventEmitter();
    @Output() enviarDireccionConstruida = new EventEmitter();//Direccion a enviar
    public zonaDireccion:any='';//Contiene la zona seleccionada desde el formulario inicial de la dirección
    public direccion:any;//COntiene la información completa de la dirección    
    public direccionAlternativa:string;//Variable que me almacenara la direccion que estoy creando antes de confirmar los cambios
    public datoAdicion:any;//Variable que me almacena la segunda parte de la barra de direccion(select)
    public adicion:string;//valor acompañado del select anterior
    public adicionales:string
    public bis:boolean= false;
    public abrirRural:boolean=false;
    public abrirUrbana:boolean=false;
    public abrirTipo:boolean=true;

    constructor() { }

    ngOnInit() {
        this.direccion = new Direccion();
        this.direccionAlternativa = '';
        this.adicionales = '';
        this.adicion = '';
        this.datoAdicion='';
        this.zonaDireccion = '';
    }

    //Cambia el formulario dependiendo el tipo de direccion seleccionada
    cargarDireccion(){
        if(this.zonaDireccion!=''){
            this.abrirTipo = false;
            if(this.zonaDireccion.id == 'U'){
                this.abrirUrbana = true;
            }else{
                this.abrirRural = true;
            }
        }        
    }

    cerrarDireccion(){
        this.enviarSenalCerrar.emit(false);
    }
    //Función que construye la dirección a medida que se van llenando los campos
    construirDireccion(){
        if(this.bis == true){
            this.direccion.bis = 'BIS';
        }else{
            this.direccion.bis = '';
        }
         if (this.direccion.via != '' ) {
            this.direccionAlternativa = this.direccion.via.id + ' ' + this.direccion.numero;

            if(this.direccion.letra.id!=undefined){
                this.direccionAlternativa += ' ' + this.direccion.letra.id;
            }
            if (this.direccion.bis == 'BIS') {
                this.direccionAlternativa += ' BIS ';
            }
            if(this.direccion.letraa.id!=undefined){
                this.direccionAlternativa +=  ' ' + this.direccion.letraa.id 
            }
            if(this.direccion.direccion.id!=undefined){
                this.direccionAlternativa += ' ' + this.direccion.direccion.id;
            }            
            if (this.direccion.numero1 != '') {
                this.direccionAlternativa += ' Nro. ' + this.direccion.numero1;
            }
            if(this.direccion.letrab.id!=undefined){
                this.direccionAlternativa += ' ' + this.direccion.letrab.id;
            }
            if (this.direccion.numero2 != '') {
                this.direccionAlternativa += ' - ' + this.direccion.numero2;
            }
            if( this.direccion.direccion1.id!=undefined){
                this.direccionAlternativa += ' ' + this.direccion.direccion1.id;
            }
        }
    }

    agregarElementoAdicional() {    
        this.adicionales = '';
        if(this.datoAdicion && this.datoAdicion.id && this.adicion){
            this.adicionales += ' ' + this.datoAdicion.id + ' ' + this.adicion;
            this.direccionAlternativa += ' '+ this.adicionales;
        }        
        this.datoAdicion = '';
        this.adicion = '';
    }

    limpiarAdicionales() {    
        this.adicionales = '';
        this.construirDireccion();
    }

    limpiarDireccion(){
        this.direccionAlternativa = '';
    }
    enviarDireccion(){
        this.enviarDireccionConstruida.emit(this.direccionAlternativa.trim());
    }
}