import { ChartOptions } from 'chart.js';

export const MEMBERCHARTOPTIONS = { responsive: true, legend: { display: true, labels: { display: true } } };

// Grafica de torta (pie)
export const MEMBERCHARTOPTIONSFORMAT = {
  responsive: true,
  legend: { display: false, position: 'left', labels: { display: true } },
  layout: {
    padding: 50
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let label = data.labels[tooltipItem.index];
        if (parseInt(value) >= 1000) { return label + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return label + ': ' + value; }
      }
    }
  },
  plugins: {
    outlabels: {
      backgroundColor: null,
      showPercentage: false,
      color: 'black',
      text: '%l',
      stretch: 20,
      font: {
        resizable: true,
        minSize: 10,
        maxSize: 15,
      },
      zoomOutPercentage: 100,
      textAlign: 'start'
    },
    datalabels: {
      color: 'transparent',
      font: {
        weight: 'bold',
        size: 10,
      }
    }
  }
};

export const BARCHARTIOPTIONS = {
  scaleShowVerticalLines: false, responsive: true,
  legend: { display: false, labels: { display: true, visible: true } },
  scales: { yAxes: [{ display: true }], xAxes: [{ display: false }] },
};

// Grafica de barras (bar)
export const BARCHARTIOPTIONSFORMAT = {
  scaleShowVerticalLines: false, responsive: true,
  legend: { display: false, labels: { display: true, visible: true } },
  layout: {
    padding: {
      top: 20
    }
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: true,
        callback: function (value) {
          if (parseInt(value) >= 1000) { return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return value; }
        }// beginAtZero: true - min:0 // Para que se vean siempre los valores que son mayores que cero, ya que cuando hay varios datos, no muestra en gráficaelmenov valor (Y enmpieza enel menor)
      }
    }],
    xAxes: [{ display: true }]
  },
  tooltips: {
    enabled: true,
    intersect: true,
    callbacks: {
      label: function (tooltipItem, data) {
        var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let label = data.datasets[tooltipItem.datasetIndex].label;
        if (parseInt(value) >= 1000) { return label + ': ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); } else { return label + ': ' + value; }
      }
    }
  },
  plugins: {
    datalabels: {
      display: false,
      align: 'end',
      anchor: 'end',
      borderRadius: 4,
      color: '#303030',
      font: {
        weight: 'bold',
        size: 10,
      }
    }
  }
}

// Grafica de lineas (line)
export const BARCHARTIOPTIONSFORMATSERIES: ChartOptions = {
  legend: { position: 'bottom' },
  plugins: {
    datalabels: {
      display: false,
      align: 'end',
      anchor: 'end',
      borderRadius: 4,
      backgroundColor: '#696868',
      color: 'white',
      font: {
        weight: 'bold',
        size: 10,
      }
    }
  }
}
