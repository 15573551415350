import { Division } from './division'

export class Region{
    public nombre:string;//Nombre del campo del formulario
    public principal:string;//Valor que contiene el campo
    public ayuda:string;//
    public divisiones: Array<Division>=[];
    public orden:string;
    public label:string; // para wizard

    constructor(region){
        this.nombre =region.nombre;
        this.principal =region.principal;
        this.ayuda=region.ayuda;
        this.orden = region.orden;
        this.label = this.nombre;
        this.adicionarDivisiones(region.divisiones);
    }

    //Función que llena el array de divisiones
    adicionarDivisiones(divisiones:any){
        if(divisiones!=null){
            for(let i=0; i<divisiones.length; i++){
                this.divisiones.push(new Division(divisiones[i]));
            }
        }else{
            this.divisiones=null;
        }
    }
} 