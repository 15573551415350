import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-vista-previa',
  templateUrl: './vista-previa.component.html',
  styleUrls: ['./vista-previa.component.css']
})
export class VistaPreviaComponent implements OnInit {
    @Output() enviarSenalCerrar = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    cerrarVentana(){
        this.enviarSenalCerrar.emit(false);
    }

}
