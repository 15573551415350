
export class campoFormulario{
    public nombre:string;//Nombre del campo del formulario
    public etiqueta:string;
    public valor:string;//Valor que contiene el campo
    public opciones:any; // Variable utilizada solo en tabla multiregistro
    public referidos:Array<campoFormulario>=null; //Utilizada solamente en tabla multiregistro
    public referidosBloqueo:Array<campoFormulario>=null; // utilizado solamente en tabla multiregistro
    public deshabilitado:boolean = false;//Utilizado solamente en multiregistro
    public valorReferidoColumna:string;

    constructor(nombre:string,etiqueta:string ,valor:string){
        this.nombre = nombre;
        this.etiqueta = etiqueta;
        this.valor = valor;
    }
} 