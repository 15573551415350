import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';

@Component({
  selector: 'app-visor-documentos',
  templateUrl: './visor-documentos.component.html',
  styleUrls: ['./visor-documentos.component.css']
})
export class VisorDocumentosComponent implements OnInit {
  @Input() url: string;
  @Input() valores: any;
  urlSanitize: SafeResourceUrl
  constructor(protected sanitizer: DomSanitizer, private _servicioDatosGenerales: DatosGeneralesService) { }

  ngOnInit() {
    this.urlSanitize = this.sanitizer.bypassSecurityTrustResourceUrl(this._servicioDatosGenerales.getUrlDescarga() + this.url)
  }

  cambiarDocumento(url: string) {
    this.url = url;
    this.urlSanitize = this.sanitizer.bypassSecurityTrustResourceUrl(this._servicioDatosGenerales.getUrlDescarga() + url)
  }

}
