import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { campoFormulario } from '@modelos/campo';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { ComunicacionService } from '@servicios/comunicacion.service';
@Component({
  selector: 'app-formulario-dialogo-tabla',
  templateUrl: './formulario-dialogo-tabla.component.html',
  styleUrls: ['./formulario-dialogo-tabla.component.css']
})
export class FormularioDialogoTablaComponent implements OnInit {
  @Input() parametros: any;
  @Input() camposPadre: any;
  @Output() enviarPeticionCerrar = new EventEmitter();
  public campos: Array<campoFormulario>;
  private idFormulario: number;
  private idmenu: string;
  public jsonFormulario: any;
  public tablaResultados: any;
  public loading: boolean = true;
  public estiloTamano: string = null;
  public titulo: string = '';
  public identificador: string;

  constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService, private _comunicacionService: ComunicacionService) { }

  ngOnInit() {
    this.prepararParametrosParaConstruccion();
  }

  prepararParametrosParaConstruccion() {
    // TODO Capturar identificador y enviarlo como parametro en el llamado
    this.campos = [];
    //TODO se debe refactrorizar el metodo para no tener dos for, uno en cada condicional
    if (this.parametros.chtmDirecto) {
      this.idFormulario = this.parametros.idchtm;
      this.titulo = this.parametros.titulo;
      for (let i = 0; i < this.parametros.constantes.length; i++) {
        if (this.parametros.constantes[i].nombre === 'identificador') {
          this.identificador = this.parametros.constantes[i].valor;
        } else {
          this.campos.push(this.parametros.constantes[i]);
        }
      }
    } else {
      for (let i = 0; i < this.parametros.constantes.length; i++) {
        if (this.parametros.constantes[i].nombre == 'idchtm') {
          this.idFormulario = this.parametros.valores[this.parametros.constantes[i].posicion];
        } else if (this.parametros.constantes[i].nombre == 'titulo') {
          this.titulo = this.parametros.valores[this.parametros.constantes[i].posicion];
        } else if (this.parametros.constantes[i].nombre == 'identificador') {
          this.identificador = this.parametros[i].valor;
        } else {
          this.campos.push(new campoFormulario(this.parametros.constantes[i].nombre, '', this.parametros.valores[this.parametros.constantes[i].posicion]));
        }
      }
    }
    switch (this.parametros.modo) {
      case '1':
        this.estiloTamano = 'dialog-container-gr'
        break;
      case '2':
        this.estiloTamano = 'dialog-container-md'
        break;
      case '3':
        this.estiloTamano = 'dialog-container-pq'
        break;
      default:
        this.estiloTamano = 'dialog-container-gr'
        break;
    }
    this.loading = false;
    // this.obtenerDatosFormulario();
  }

  // // función que consume al bus de servicio solicitando el tipo de formulario y su estructura
  // obtenerDatosFormulario() {
  // 	this.loading = true;
  // 	let mensaje: string = '';
  // 	let codigoSesion = this._servicioDatosGenerales.getIdentity().codigoSesion;
  // 	let llamado = { "accion": "construir", "parametros": { "codigoSesion": codigoSesion, "id_menu": "", "idFormulario": this.idFormulario, "identificador": "0", "camposBusqueda": this.campos } };
  // 	this._servicioPeticiones.enviarObjetoBus(llamado)
  // 		.subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
  // 			response => { //si la peticion fue exitosa ejecuta la funcion response
  // 				let respuesta = response;
  // 				let codigoPaso: string;
  // 				let resultado: any;
  // 				for (let i = 0; i < respuesta.length; i++) {
  // 					resultado = respuesta[i].valores;
  // 					if (resultado && resultado.resultado == 0) {
  // 						codigoPaso = respuesta[i].codigPaso;
  // 						if (codigoPaso == 'I') {//Inicialmente, carga el tipo de formulario
  // 							this.jsonFormulario = resultado.formulario;
  // 							if (this.titulo == '') {
  // 								this.titulo = this.jsonFormulario.nombre;
  // 							}
  // 							this.buscarRegistrosFormulario();
  // 						}
  // 					} else {
  // 						i = 2;
  // 						if (resultado && resultado.mensaje) {
  // 							mensaje = resultado.mensaje;//VERIFICAR LA MANERA DE PONER EL MENSAJE
  // 						} else {
  // 							mensaje = 'Ocurrió un error inesperado, por favor contáctese con el administrador del sistema';
  // 						}
  // 						this._servicioDatosGenerales.enviarMensaje('E', mensaje);
  // 						this.loading = false;
  // 					}
  // 				}
  // 			},
  // 			error => {
  // 				this.loading = false;
  // 				let mensaje = 'Ocurrió un error inesperado al intentar cargar el formulario, por favor contáctese con el administrador del sistema';
  // 				this._servicioDatosGenerales.enviarMensaje('E', mensaje);
  // 			}
  // 		)
  // }

  // buscarRegistrosFormulario() {
  // 	if (!this._servicioDatosGenerales.getFormularioBloqueado()) {
  // 		// let idRegistroPadreEnviar:string=this.verificarIdentificadorPadre();
  // 		// this.loading = true;
  // 		let llamado = { "accion": "busqueda", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "formulario": this.idFormulario, "identificador": null, "identificadorPadre": null, "campos": this.campos, "palabraClave": "", "pagina": "0", "cantidadRegistros": "10" } };
  // 		this._servicioPeticiones.enviarObjetoBus(llamado)
  // 			.subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
  // 				response => { //si la peticion fue exitosa ejecuta la funcion response
  // 					let respuesta = response[0].valores;
  // 					if (respuesta) {
  // 						if (respuesta['resultado'] == '0') {//Si No obtiene problemas al traer el menú
  // 							if (respuesta.tablaResultados != null) {
  // 								this.tablaResultados = respuesta.tablaResultados;
  // 								// this.definirValoresPaginaTabla();
  // 							}
  // 							this.loading = false;
  // 						} else {
  // 							this._servicioDatosGenerales.enviarMensaje('E', respuesta['mensaje']);
  // 							this.cerrarDialogo();
  // 							this.loading = false;
  // 						}
  // 					} else {
  // 						this.loading = false;
  // 						this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
  // 						this.cerrarDialogo();
  // 					}
  // 				},
  // 				error => {
  // 					this.loading = false;
  // 					this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
  // 					this.cerrarDialogo();
  // 				}
  // 			)
  // 	} else { // Para limpiar los intervalos ya que cuando se desbloquee correrá otro intervalo distinto
  // 		// if(this.intervaloRecarga){
  // 		// 	if(this.idmenu){
  // 		// 		this._servicioDatosGenerales.eliminarProcesoListaMenu(this.idmenu);
  // 		// 	}else{
  // 		// 		this._servicioDatosGenerales.eliminarProcesoListaProceso(this.intervaloRecarga);
  // 		// 	}
  // 		// 	this.intervaloRecarga = null;
  // 		// }
  // 	}
  // }

  cerrarDialogo() {
    this.enviarPeticionCerrar.emit();
    if (this._servicioPeticiones.getRecargaPadre()) {

      setTimeout(() => {
        this._comunicacionService.recargarTablaPrincipal.emit();
      }, 2000);
    }
  }
}
