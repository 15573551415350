export class Direccion{
    public via:any
    public numero:any
    public letra:any
    public bis :any
    public letraa:any
    public direccion:any
    public numero1:any
    public letrab:any
    public numero2:any
    public direccion1:any
    public dato_adicional:any
    public adicion:any
    public segunda_parte:any
    public valor_direccion:any
    
    constructor(){
        this.via= '';
        this.numero= '';
        this.letra= '';
        this.bis = '';
        this.letraa= '';
        this.direccion= '';
        this.numero1= '';
        this.letrab= '';
        this.direccion1= '';
        this.numero2= '';
        this.dato_adicional= '';
        this.adicion= '';
        this.segunda_parte = '';
        this.valor_direccion= '';
    }
} 