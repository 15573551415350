import { Region } from './region'

export class Formulario{
    public id:string;//Id del formulario
    public tipo:string;//Tipo del formulario
    public nombre:string;//Nonmbre del formulario
    public descripcion:string;
    public ayuda:string;
    public permisos:any
    public regiones: Array<Region>=[];
    public parametrosReporte:any;
    public aexcel:string;
    public recargaPadre:boolean;
    public referidoReporte:string;
    public campoProcesa:string;
    public idtabl:string;
    public lineal:string;
    public campoLineal:string;
    public salida:string;
    public campoPaso:string;
    public cerrarPersistir:string;
    public espera:string;

    constructor(formulario:any){
        this.id = formulario.id;
        this.tipo = formulario.tipo;
        this.nombre = formulario.nombre;
        this.descripcion = formulario.descripcion;
        this.ayuda = formulario.ayuda;
        this.permisos = formulario.permisos;
        this.parametrosReporte = formulario.parametrosReporte;
        this.aexcel = formulario.aexcel;
        this.recargaPadre = formulario.recargaPadre;
        this.referidoReporte = formulario.referidoReporte;
        this.campoProcesa = formulario.campoProcesa;
        this.idtabl = formulario.tabla;
        this.lineal = formulario.lineal;
        this.campoLineal = formulario.campoLineal;
        this.salida = formulario.salida;
        this.campoPaso = formulario.campoPaso;
        this.cerrarPersistir = formulario.cerrarPersistir;
        this.espera = formulario.espera;
        this.adicionarRegiones(formulario.regiones);
    }

    //Función que llena el array de divisiones
    adicionarRegiones(regiones:any){
        if(regiones!=null){
            for(let i=0; i<regiones.length; i++){
                this.regiones.push(new Region(regiones[i]));
            }
        }else{
            this.regiones=null;
        }
    }
} 